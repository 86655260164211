<template>
  <a-modal v-model="visible"
           :title="title"
           :mask-closable="false"
           :confirm-loading="confirmLoading"
           :width="800"
           dialog-class="add-class-modal"
           @ok="submit">
    <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item label="复制已有班次信息">
        <a-select placeholder="请选择班次" v-model="copyId" @change="copyIdChange">
          <a-select-option
            v-for="item in classSelectList"
            :value="item.id"
            :key="item.id"
          >{{ item.title }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <div>
        <h3>班次信息</h3>
        <div class="class-box">
          <a-form-item label="班次名称">
            <a-input v-decorator="['title', decorator.title]" placeholder="请输入班次名称"/>
          </a-form-item>
          <a-form-item label="班次容量">
            <a-input-number
              style="width: 170px;"
              v-decorator="['contain_num', decorator.contain_num]"
              :formatter="value => `${value}`.replace(/\D*/g, '')"
              :parser="value => `${value}`.replace(/\D*/g, '')"
              :min="1"
              placeholder="请输入班次容量"
            />
            <span class="ml-10">人</span>
          </a-form-item>
          <a-form-item label="讲师">
            <a-select
              v-decorator="['teacher_id']"
              placeholder="请选择讲师"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            >
              <a-select-option
                v-for="item in teacherSelectList"
                :value="item.id"
                :key="item.id"
              >{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>
      </div>
      <div>
        <div class="class-switch">
          <h3>培训信息</h3>
          <a-switch v-model="is_show_training_info" default-checked
                    @change="(e) => handlerSwitch(e, 'is_show_training_info')"/>
          <p class="txt">关闭开关则培训信息页不显示</p>
        </div>
        <div class="class-box">
          <a-form-item label="培训地点">
            <a-input v-decorator="['address', decorator.address]" placeholder="请输入培训地点"/>
          </a-form-item>
          <a-form-item label="培训时间">
            <a-range-picker
              v-decorator="['trainingDate', decorator.trainingDate]"
              :show-time="{ format: 'HH:mm' }"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              format="YYYY-MM-DD HH:mm"
            />
          </a-form-item>
          <a-form-item label="联系人">
            <a-input v-decorator="['contract']" placeholder="请输入联系人"/>
          </a-form-item>
          <a-form-item label="联系电话">
            <a-input v-decorator="['contract_phone']" placeholder="请输入联系电话"/>
          </a-form-item>
        </div>
      </div>
      <div>
        <div class="class-switch">
          <h3>报到信息</h3>
          <a-switch v-model="is_show_reports_info" default-checked
                    @change="(e) => handlerSwitch(e, 'is_show_reports_info')"/>
          <div class="txt">关闭开关则报到信息页不显示</div>
        </div>
        <div class="class-box">
          <a-form-item label="报到时间">
            <a-range-picker
              v-decorator="['checkInTime', decorator.checkInTime]"
              :show-time="{ format: 'HH:mm' }"
              format="YYYY-MM-DD HH:mm"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            />
          </a-form-item>
          <a-form-item label="报到地点">
            <a-input
              v-decorator="['reg_address', decorator.reg_address]"
              disabled
              placeholder="请输入报到地点"
            />
            <a-button type="primary" @click="selectCheckInAddress">选择报到地点</a-button>
          </a-form-item>
          <a-form-item v-show="false" label="报到地点">
            <a-input v-decorator="['reg_address_coor', decorator.reg_address_coor]" disabled/>
          </a-form-item>
        </div>
      </div>
      <!-- <a-form-item label="住宿地点">
        <a-input v-decorator="['hotel_address']" placeholder="请输入住宿地点" />
      </a-form-item>-->
      <TemInfo ref="temInfo" :customList="customList" @deleteTemInfo="deleteTemInfo"/>
    </a-form>
    <div class="class-switch">
      <a-button type="primary" @click="handlerAddTemp" ghost>添加信息模版</a-button>
    </div>
    <SelectMap
      ref="selectAddressMap"
      title="选择报道地点"
      :latLng="latLng"
      @success="selectAddressSuccess"
    />
  </a-modal>
</template>

<script>
import moment from 'moment';

import {
  getClassDetail,
  getClassSelectList,
  getTeacherSelectList,
  saveClassDetail,
} from '../api/config.class';
import { geocoder } from '@/components/map/api/commonMap';
import SelectMap from '../components/selectMap';
import TemInfo from './TemInfo';
// import UploadImageGroup from './UploadImageGroup';

const decorator = {
  title: {
    rules: [{ required: true, message: '请输入班次名称!' }],
  },
  contain_num: {
    rules: [{ required: true, message: '请输入班次容量!' }],
  },
  address: {
    rules: [{ required: true, message: '请输入培训地点!' }],
  },
  checkInTime: {
    rules: [{ required: true, message: '请选择报到时间!' }],
  },
  trainingDate: {
    rules: [{ required: true, message: '请选择培训时间!' }],
  },
  reg_address: {
    rules: [{ required: true, message: '请输入报到地点!' }],
  },
  reg_address_coor: {
    rules: [{ required: false, message: '' }],
  },
  hotel_address: {
    rules: [{ required: false }],
  },
  teacher_id: {
    rules: [{ required: false, message: '请选择讲师!' }],
  },
  contract: {
    rules: [{ required: false }],
  },
  contract_phone: {
    rules: [{ required: false }],
  },
};
export default {
  name: 'AddClassModal',
  components: {
    SelectMap,
    // UploadImageGroup,
    TemInfo,
  },
  props: {
    trainingId: { type: [Number, String], default: '' },
  },
  computed: {
    title() {
      return this.id ? '修改班次' : '添加班次';
    },
    confirmLoading() {
      return this.loading || this.uploading?.some((i) => !!i);
    },
  },
  data() {
    return {
      decorator,
      loading: false,
      visible: false,
      postId: '', // 岗位 id
      id: '', // 班次 id
      copyId: undefined, // 复制班次 id

      classSelectList: [], // 班次列表
      teacherSelectList: [], // 讲师列表

      latLng: [], // 经纬度字符串

      uploading: [],

      form: this.$form.createForm(this, {}),
      customList: [],
      is_show_training_info: true,
      is_show_reports_info: true,
    };
  },
  created() {
    this.getTeacherSelectList();
    this.form.getFieldDecorator('keys', { initialValue: [], preserve: true });
  },
  methods: {
    show({ id, postId } = {}) {
      this.form = this.$form.createForm(this, {});
      this.postId = postId;
      this.id = id;
      this.copyId = undefined;

      this.customList = [];

      this.getClassSelectList();
      this.getData(id);

      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    /**
     * 获取该培训下所有班次，复制已有班次使用
     * @returns {Promise<void>}
     */
    async getClassSelectList() {
      const data = await getClassSelectList({
        training_id: this.trainingId,
      });
      if (!data || data.error_code) {
        return;
      }
      this.classSelectList = data?.data || [];
    },
    copyIdChange() {
      this.getData(this.copyId);
    },

    /**
     * 获取讲师列表
     * @returns {Promise<void>}
     */
    async getTeacherSelectList() {
      const data = await getTeacherSelectList({
        training_id: this.trainingId,
      });
      if (!data || data.error_code) {
        return;
      }
      this.teacherSelectList = data?.data || [];
    },

    /**
     * 打开地图弹窗
     */
    selectCheckInAddress() {
      this.$refs.selectAddressMap.openMe();
    },
    deleteTemInfo(index) {
      this.customList.splice(index, 1);
    },
    /**
     * 选择报道地点详细地址，经纬度
     * @param recode
     * @returns {Promise<void>}
     */
    async selectAddressSuccess(recode) {
      this.latLng = [recode.lng, recode.lat];
      this.form.setFieldsValue({
        reg_address: recode?.addr,

        address_coor: [recode.lng, recode.lat].toString(), // 培训地址定位坐标(经度,纬度)
        reg_address_coor: [recode.lng, recode.lat].toString(), // 报到地点定位坐标(经度,纬度)
      });
      if (!recode.addr) {
        const data = await geocoder({
          location: [recode.lat, recode.lng].toString(),
          get_poi: 1,
        });
        if (data?.status === 0) {
          this.reg_address = data?.result?.address;
          this.form.setFieldsValue({
            reg_address: this.reg_address,
          });
        }
      }
    },

    getFieldDecorator() {
      Object.keys(this.decorator).forEach((key) => {
        this.form.getFieldDecorator(key, this.decorator[key]);
      });
    },
    /**
     * 给表单赋值
     */
    setFields(data) {
      const fields = {};
      Object.keys(this.decorator).forEach((i) => {
        fields[i] = data[i];
      });
      fields.trainingDate = [moment(data?.start_date), moment(data?.end_date)];
      fields.checkInTime = [
        data?.sign_start_date_fmt
          ? moment(
          `${data?.sign_start_date_fmt?.split(' ')?.[0]} ${data.sign_time.split('-')?.[0] ?? ''}`,
          )
          : moment(),
        data?.sign_end_date_fmt
          ? moment(
          `${data?.sign_end_date_fmt?.split(' ')?.[0]} ${data.sign_time.split('-')?.[1] ?? ''}`,
          )
          : moment(),
      ];

      this.latLng = data.address_coor?.split(',');
      this.customList = data.custom.map((item) => ({
          ...item,
          classes_id: item.classes_id,
          id: item.id,
          title: item.title,
          info: item.info || []
      }))
      this.is_show_reports_info = data.is_show_reports_info === 1 ? true : false;
      this.is_show_training_info = data.is_show_training_info === 1 ? true : false;

      this.form.setFieldsValue({ ...fields });
    },
    handlerAddTemp() {
      this.customList.push({
        title: '',
        info: [],
      });
    },
    /**
     * 图片组上传状态更新
     * @param evt
     * @param index
     */
    uploadingChange(evt, index) {
      this.uploading[index] = evt;
      this.uploading = [...this.uploading];
    },
    /**
     * 图片组标题更新
     * @param evt
     * @param item
     */
    imgGroupTitleChange(evt, item) {
      item.title = evt || '';
    },

    /**
     * 获取班次信息
     * @param id
     * @returns {Promise<void>}
     */
    async getData(id) {
      if (!id && !this.id) {
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await getClassDetail({ id: id || this.id }).finally(() => {
        this.loading = false;
      });

      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取班次信息失败');
        return;
      }
      this.setFields(data?.data || {});
    },
    handlerSwitch(e, type) {
      this[type] = e;
    },
    /**
     * 验证保存信息
     */
    submit() {
      const isTemCardValid = [];
      const temInfoValid = this.$refs?.temInfo?.validateForm();
      this.$refs?.temInfo?.$refs?.temCard?.forEach((item) => {
        isTemCardValid.push(item.validateForm());
      });
      if (!temInfoValid || isTemCardValid.includes(false)) {
        return false;
      }
      this.form.validateFields((err, values) => {
        if (err) {
          this.$message.info({ content: '请填写必填项' });
          this.loading = false;
          return;
        }

        const params = {
          ...values,
          training_id: this.trainingId,
          post_id: this.postId,
          start_date: values.trainingDate?.[0]?.format('YYYY-MM-DD HH:mm') || '',
          end_date: values.trainingDate?.[1]?.format('YYYY-MM-DD HH:mm') || '',
          sign_start_date: values.checkInTime?.[0]?.format('YYYY-MM-DD HH:mm') || '',
          sign_end_date: values.checkInTime?.[1]?.format('YYYY-MM-DD HH:mm') || '',
          sign_time: values.checkInTime?.[0]?.format('HH:mm') + '-' + values.checkInTime?.[1]?.format('HH:mm'),
          is_show_training_info: this.is_show_training_info ? 1 : 0,
          is_show_reports_info: this.is_show_reports_info ? 1 : 0,
          custom: JSON.stringify(this.$refs.temInfo.customList),
        };
        delete params.trainingDate;
        delete params.checkInTime;

        if (this.id) {
          params.id = this.id;
        }

        this.save(params);
      });
    },
    /**
     * 保存班次信息
     * @param params
     * @returns {Promise<void>}
     */
    async save(params) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await saveClassDetail(params).finally(() => {
        this.loading = false;
      });

      if (!data || data.error_code) {
        this.$message.error(data?.message || '保存失败');
        return;
      }
      this.$message.success(data?.message || '保存成功');
      this.$emit('success');
      this.close();
    },
  },
};
</script>

<style lang="scss">
.add-class-modal .ant-modal-body {
  max-height: 70vh;
  overflow-y: auto;
}

.class-switch {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;

  h3 {
    margin-right: 10px;
  }

  .txt {
    color: #CCC;
    margin-left: 10px;
  }

}

.class-box {
  background-color: rgb(242, 242, 242);
  padding: 15px;
  position: relative;
  margin-top: 10px;
}
</style>
