import request from '@/utils/Request';

// 基础信息——本部审批人
export function getApprover() {
  return request({
    url: '/manage/admin/manage/managementList',
    method: 'get'
  });
}

// 证书模板-下拉接口查询
// 参数：cert_type 证书类型[1:课程2:任务3:培训]
export function getCertificateTemplates(params) {
  return request({
    url: `/public/conf/cert/template`,
    method: 'get',
    params,
  });
}

// 证书领域类型-下拉接口查询
export function getCertificateFields() {
  return request({
    url: '/public/conf/cert/domaincate',
    method: 'get',
  });
}
