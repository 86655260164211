<template>
  <div class="pd20">
    <div class="pb10">
      允许调整班次：
      <a-radio-group
        v-model="is_allow_adjust_class"
        name="radioGroup"
        :default-value="1"
        @change="handlerClassConfig"
      >
        <a-radio :value="1"> 是</a-radio>
        <a-radio :value="2"> 否</a-radio>
      </a-radio-group>
    </div>
    <div v-for="item in data" :key="item.id">
      <div class="flex-box ptb20">
        <CommonTitle>{{ item.post_name }}</CommonTitle>
        <a-button @click="openLayer(item.id)" type="primary">
          添加班次
        </a-button>
      </div>
      <a-table
        :columns="columns"
        :data-source="item.list"
        bordered
        :pagination="false"
        @change="handleTableChange"
        :loading="loading"
        row-key="id"
      >
        <template slot="action" slot-scope="text, record">
          <a @click="openLayer(item.id, record)">编辑</a>
          <a-divider type="vertical"/>
          <a @click="stopResume(record)">{{
              +record.is_stop === 1 ? '恢复报名' : '停止报名'
            }}</a>
          <a-divider type="vertical"/>
          <a @click="deleteData(record)">删除</a>
        </template>
      </a-table>
    </div>
    <saveButtons
      ref="saveButtons"
      @handleSubmit="handleSubmit"
      @saveAndNext="saveAndNext"
    ></saveButtons>
    <addClassLayer ref="layer" @success="getData"></addClassLayer>
    <AddClassModal ref="addClassModalRef"
                   :training-id="$route.query.id"
                   @success="getData"/>
  </div>
</template>

<script>
import {
  getClassList,
  stopResumeSign,
  deleteClass,
  getClassConfig,
  setClassConfig,
} from '@/api/config';
import { Divider } from 'ant-design-vue';
import { ConfigMixin } from './mixins/configMixins';

import AddClassModal from './components/AddClassModal';

export default {
  name: 'classConfiguration',
  mixins: [ConfigMixin],
  components: {
    CommonTitle: () => import('./components/commonTitle'),
    addClassLayer: () => import('./components/addClassLayer'),
    saveButtons: () => import('./components/saveButtons'),
    'a-divider': Divider,
    AddClassModal,
  },
  data() {
    return {
      columns: [
        {
          title: '班次名称',
          dataIndex: 'title',
          scopedSlots: { customRender: 'title' },
        },
        {
          title: '班次容量',
          dataIndex: 'contain_num',
          scopedSlots: { customRender: 'contain_num' },
          customRender: (text) => {
            return text + '人';
          },
        },
        {
          title: '培训地点',
          dataIndex: 'address',
          scopedSlots: { customRender: 'address' },
        },
        {
          title: '培训日期',
          dataIndex: 'training_date',
          scopedSlots: { customRender: 'training_date' },
        },
        {
          title: '报到时间',
          dataIndex: 'sign_date',
          scopedSlots: { customRender: 'sign_date' },
          customRender: (text) => {
            return text;
          },
        },
        {
          title: '报到地点',
          dataIndex: 'reg_address',
          scopedSlots: { customRender: 'reg_address' },
        },
        {
          title: '联系人',
          dataIndex: 'contract',
          scopedSlots: { customRender: 'contract' },
          customRender: (text) => {
            return text ? text : '--';
          },
        },
        {
          title: '联系电话',
          dataIndex: 'contract_phone',
          scopedSlots: { customRender: 'contract_phone' },
          customRender: (text) => {
            return text ? text : '--';
          },
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      data: [],
      loading: false,
      pagination: [],
      paginationAll: {},
      stopResumeSign,
      is_allow_adjust_class: 1, // 是的允许调整班次
      isAdd: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    handleTableChange() {
      this.handleSearch();
    },
    getData() {
      this.paginationAll.current = 1;
      this.paginationAll.pageSize = 10;
      this.handleSearch();
    },
    handleSearch() {
      let params = {
        training_id: this.$route.query.id,
        page: this.pagination.current,
        per_page: this.pagination.pageSize,
      };
      this.apiClassConfig();
      this.getApiData(params);
    },
    async getApiData(params) {
      this.loading = true;
      const data = await getClassList(params).finally(
        () => (this.loading = false),
      );
      if (!data || data.error_code) {
        return;
      }
      if (data) {
        this.data = data.data;
        this.pagination.length = data.data.length;
        data.data.length && this.pagination.fill({ pagination: {} });
      }
    },
    handlerClassConfig({ target }) {
      this.is_allow_adjust_class = target?.value ?? 1 ?? 1;
    },
    openLayer(id, data) {
      // this.$refs.layer.openMe(id, data);
      this.$refs.addClassModalRef.show({
        postId: id, id: data?.id,
      });
    },
    handleSubmit() {
      this.goNextStep('checkWorkAttendanceConfiguration');
      this.isAdd = true;
      this.apiClassConfig();
    },
    async delApiData(record) {
      this.loading = true;
      const data = await deleteClass({ id: record.id }).finally(
        () => (this.loading = false),
      );
      if (!data || data.error_code) {
        this.$message.error(data.message);
        return;
      }
      if (data) {
        this.$message.success(data.message);
        this.getData();
      }
    },
    async apiClassConfig() {
      const params = this.isAdd
        ? {
          training_id: this.$route.query.id,
          is_allow_adjust_class: this?.is_allow_adjust_class ?? 1,
        }
        : { training_id: this.$route.query.id };
      const api = this.isAdd ? setClassConfig(params) : getClassConfig(params);
      const { data } = await api;
      if (data?.id > 0) {
        this.is_allow_adjust_class =
          data?.is_allow_adjust_class > 0
            ? data.is_allow_adjust_class ?? 1
            : this?.is_allow_adjust_class ?? 1;
      }
    },
    async stopResume(record) {
      this.loading = true;
      let type = +record.is_stop === 1 ? 0 : 1;
      const data = await stopResumeSign({ id: record.id, type }).finally(
        () => (this.loading = false),
      );
      if (!data || data.error_code) {
        this.$message.error(data.message);
        return;
      }
      if (data) {
        this.$message.success(data.message);
        setTimeout(() => {
          this.getData();
        });
      }
    },
  },
};
</script>

<style scoped>
.flex-box {
  display: flex;
  justify-content: space-between;
}

.pd20 {
  padding: 20px;
}

.text-center {
  text-align: center;
}

.pb10 {
  padding-bottom: 10px;
}

.ptb20 {
  padding: 20px 0;
}
</style>
