import { render, staticRenderFns } from "./SelectDMSPeopleModal.vue?vue&type=template&id=18d66d61&scoped=true&"
import script from "./SelectDMSPeopleModal.vue?vue&type=script&lang=js&"
export * from "./SelectDMSPeopleModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18d66d61",
  null
  
)

export default component.exports