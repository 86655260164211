<template>
  <div class="offlineSign">
    <div class="searchForm">
      <div class="item">
        <span>学员名称：</span>
        <a-input-search
          style="width: 160px"
          v-model="stuName"
          placeholder="请输入学员名称"
        />
      </div>
      <div class="item" v-if="type === 1">
        <span>班次：</span>
        <SelectClasses
          :show-label="false"
          :disabled="readOnly"
          v-model="trainClass"
          :list="classList"
          @select="selectClasses"
        ></SelectClasses>
      </div>
      <div class="item" v-if="type === 2">
        <span>*阶段：</span>
        <a-select v-model="stageId" placeholder="请选择阶段" style="width: 160px"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode">
          <a-select-option
            v-for="item of stageList"
            :key="item.id"
            :value="item.id"
          >{{ item.title }}
          </a-select-option
          >
        </a-select>
      </div>
      <div class="item" v-if="type === 1">
        <span>报到状态：</span>
        <a-select v-model="reportStatus" style="width: 160px"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode">
          <a-select-option
            v-for="item of reportList"
            :key="item.id"
            :value="item.id"
          >{{ item.title }}
          </a-select-option
          >
        </a-select>
      </div>
      <div class="item" v-if="type === 1">
        <span>结业状态：</span>
        <a-select v-model="graduationStatus" style="width: 160px"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode">
          <a-select-option
            v-for="item of graduationList"
            :key="item.id"
            :value="item.id"
          >{{ item.title }}
          </a-select-option
          >
        </a-select>
      </div>
    </div>
    <div class="searchForm">
      <div class="row row-left">
        <div class="col col-left item">
          <span class="title">岗位：</span>
          <SelectJob
            :show-label="false"
            :disabled="readOnly"
            v-model="postIds"
            @select="selectJob"
          ></SelectJob>
        </div>
      </div>

      <div class="row row-left">
        <div class="col col-left">
          <span class="title">*组织机构：</span>
          <SelectOrg
            class="selectOrg"
            :show-label="false"
            :disabled="readOnly"
            :max="trainingType === 2 ? 1 : 0"
            :data="trainingType === 2 ? orgList : undefined"
            v-model="orgIds"
          ></SelectOrg>
        </div>
      </div>
      <div class="row mt-30 ph-20">
        <div class="col col-left">
          <a-button :disabled="readOnly" type="primary" @click="search()"
          >搜索
          </a-button
          >
          <a-button @click="reset">重置</a-button>
        </div>
      </div>
    </div>
    <div class="actionDom">
      <a-button v-if="isAppoint" @click="importPeopleList">
        导入人员名单
        <!--        <a-input class="fileUpload" type="file" @change="filePlChange"></a-input>-->
      </a-button>
      <a-button v-if="type === 1" @click="importAuthGrade">
        导入认证成绩
      </a-button>
      <a-button v-if="type === 1" @click="applyGraduation">公布结业状态</a-button>
      <a-button @click="download()">导出</a-button>
    </div>
    <div class="table">
      <a-table :columns="parsedColumns" :data-source="data" :pagination="false" :loading="loading" bordered
               row-key="user_id"
               :scroll="{ x: 1800 }">
        <span slot="report_status" slot-scope="text">{{ text === 0 ? '未报到' : '已报到' }}</span>
        <span slot="graduation_status" slot-scope="text">{{ text === 0 ? '未结业' : (text === 1 ? '已结业' : '--') }}</span>
        <span class="actionBox" slot="action" slot-scope="text, record">
          <a-button type="link" @click="changeStatus(record.id)">变更结业状态</a-button>
          <a-button v-if="trainingType === 1" type="link" @click="cancelSignUp(record)">
            取消报名
          </a-button>
          <a-button v-if="trainingType === 1" type="link" @click="adjustClass(record)">
            调整班次
          </a-button>
        </span>
        <template slot="footer">
          <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>
    <a-modal
      title="导入文档"
      :visible="visible"
      ok-text="下载文档"
      cancel-text="取消"
      @ok="getErrorFile"
      @cancel="visible = false"
    >
      <p>异常信息请查看文档</p>
    </a-modal>

    <ImportFileModal ref="importFileModalRef"/>
    <SelectClassModal ref="selectClassModalRef"
                      @success="getData(
                        pagination.current,
                        pagination.pageSize
                      )"/>
  </div>
</template>

<script>
import SelectOrg from '@/components/select/SelectOrg';
import SelectJob from '@/components/select/SelectJob';
import SelectClasses from '@/components/select/SelectClasses';
import Common from '@/utils/Common';
import {
  getTrainApplyList,
  updateApplyUgs,
  saveImportcg,
  saveImportpl,
  postApplyGraduation,
} from '@/api/offline/Details';
import Pagination, { getPagination } from '@components/Pagination';
import { mapState } from 'vuex';
import { getTrainingOrgTree } from './api/api';
import { cancelSignUp } from './api/sign.up';
import SelectClassModal from './components/SelectClassModal';

import ImportFileModal from '../components/ImportFileModal';

export default {
  name: 'offlineSign',
  components: {
    SelectOrg,
    SelectJob,
    SelectClasses,
    Pagination,
    SelectClassModal,
    ImportFileModal,
  },
  computed: {
    ...mapState({
      trainingType: (state) => state.Offline.trainType,
      isCertIssue: (state) => state.Offline.isCertIssue,
    }),

    parsedColumns() {
      return this.columns.filter((i) => {
        if (this.trainingType === 2) {
          return !['report_status', 'certification_grade', 'certificate_number', 'graduation_status', 'action'].includes(i.key);
        }
        if (!this.isCertIssue) {
          return !['certificate_number'].includes(i.key);
        }
        return true;
      });
    },
  },
  watch: {
    trainingType: {
      immediate: true,
      handler(type) {
        if (+type === 2) {
          this.getTrainingOrgTree();
        }
      },
    },
  },
  data() {
    return {
      id: this.$route.query.id,//培训Id
      type: 0,//培训Type
      isAppoint: 0,//是否手动指派
      classList: [],//班次筛选
      stageList: [],//阶段筛选
      reportList: [],//报到状态
      graduationList: [],//报到状态
      columns: [
        {
          width: 120,
          fixed: 'left',
          align: 'center',
          dataIndex: 'truename',
          key: 'truename',
          title: '姓名',
        },
        {
          width: 80,
          align: 'center',
          dataIndex: 'regional_org_name',
          key: 'regional_org_name',
          title: '大区',
        },
        {
          width: 100,
          align: 'center',
          dataIndex: 'small_regional_org_name',
          key: 'small_regional_org_name',
          title: '小区',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'orgname',
          key: 'orgname',
          title: '经销商',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'code',
          key: 'code',
          title: '机构代码',
        },
        {
          width: 160,
          align: 'center',
          dataIndex: 'idcard',
          key: 'idcard',
          title: '身份证号',
        },
        {
          width: 120,
          align: 'center',
          dataIndex: 'mobile',
          key: 'mobile',
          title: '电话',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'postname',
          key: 'postname',
          title: '岗位',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'classes_name',
          key: 'classes_name',
          title: '班次',
        },
        {
          width: 100,
          align: 'center',
          dataIndex: 'report_status',
          key: 'report_status',
          title: '报到状态',
          scopedSlots: { customRender: 'report_status' },
        },
        {
          width: 100,
          align: 'center',
          dataIndex: 'certification_grade',
          key: 'certification_grade',
          title: '认证成绩',
        },
        {
          width: 100,
          align: 'center',
          dataIndex: 'certificate_number',
          key: 'certificate_number',
          title: '证书状态',
          customRender: (text, record) => {
            if (!record.certificate_user_file_url) {
              return <span style="color: red;">{text}</span>;
            }
            return this.$createElement(
              'span',
              {
                style: {
                  cursor: 'pointer',
                  color: '#1890ff',
                },
                on: {
                  click: () => window.open(record.certificate_user_file_url, '_blank'),
                },
              },
              text,
            );
          },
        },
        {
          width: 100,
          fixed: 'right',
          align: 'center',
          dataIndex: 'graduation_status',
          key: 'graduation_status',
          title: '结业状态',
          scopedSlots: { customRender: 'graduation_status' },
        },
        {
          width: 130,
          fixed: 'right',
          title: '操作',
          align: 'center',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      data: [],
      loading: false,
      readOnly: false,
      visible: false,//弹出框是否显示
      errorFile: '',//导入错误文档地址
      pagination: getPagination(),
      postIds: '', //选择岗位id
      orgIds: this.$route.query?.orgId || '', //选择机构id
      stuName: '',//学员姓名
      trainClass: this.$route.query?.classId || undefined,//班次id
      stageId: Number(this.$route.query?.stage) || undefined,//阶段id
      graduationStatus: '-1',
      reportStatus: '-1',

      orgList: undefined,
    };
  },
  async created() {
    await this.$store.dispatch('Offline/getDetails', this.id);
    const Details = this.$store.state.Offline.trainDetails;
    this.type = Details.training_type;
    this.isAppoint = Details.is_appoint;
    this.classList = Details.classes_id;
    this.stageList = Details.stage;
    this.reportList = Details.report_status;
    this.graduationList = Details.graduation_status;
    if (this.type === 2) {
      this.columns.find(obj => obj.key === 'classes_name').title = '阶段';
    } else {
      this.getData();
    }
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    /**
     * 辅导业务，获取参与辅导的组织机构
     * @returns {Promise<void>}
     */
    async getTrainingOrgTree() {
      const data = await getTrainingOrgTree({
        training_id: this.id,
      });
      this.orgList = data?.data || undefined;
    },
    async getData(page, pageSize, isDownload) {
      if (+this.trainingType === 2) {
        if (!this.stageId) {
          this.$message.info('请选择阶段');
          return;
        }
        if (!this.orgIds) {
          this.$message.info('请选择组织机构');
          return;
        }
      }
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;
      this.loading = true;
      const params = {
        training_id: this.id || 1,
        training_type: this.type || 1,
        truename_or_username_or_idcard: this.stuName || '',
        org_id_str: this.orgIds || '',
        post_id_str: this.postIds || '',
        classes_id_str: this.trainClass || '',
        stage: this.type === 2 ? this.stageId : '',
        report_status: this.reportStatus || -1,
        graduation_status: this.graduationStatus || -1,
        is_download: isDownload ? isDownload : '',
      };
      const data = await getTrainApplyList({
        page: page,
        per_page: pageSize,
        ...params,
      }, isDownload).finally(() => this.loading = false);
      if (!data || data.error_code || isDownload) {
        return;
      }
      this.data = data?.data || [];
      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
      return data;
    },
    //选择班次
    selectClasses(event) {
      this.trainClass = event && event.length ? event.join(',') : '';
    },
    //选择岗位
    selectJob(event) {
      this.postIds = event && event.length ? event.join(',') : '';
    },
    // 选择机构
    selectOrg(event) {
      const data = event?.data || [];
      this.orgIds = data.map((i) => i.id).join(',');
    },
    search() {
      this.getData();
    },
    reset() {
      this.postIds = ''; //选择岗位id
      this.orgIds = ''; //选择机构id
      this.stuName = '';//学员姓名
      this.trainClass = undefined;
      this.stageId = undefined;
      this.graduationStatus = '-1';
      this.reportStatus = '-1';
      this.getData();
    },
    // 变更结业状态
    changeStatus(id) {
      this.$confirm({
        content: () => <div>确认变更？</div>,
        onOk: async () => {
          const data = await updateApplyUgs({ id: id });
          if (!data || data.error_code) {
            this.$message.error(data.message);
            return;
          }
          this.getData();
          this.$message.success(data.message);
        },
      });
    },
    // 公布结业状态
    async applyGraduation() {
      this.$confirm({
        content: () => <div>确认公布结业状态？</div>,
        onOk: async () => {
          const data = await postApplyGraduation({
            training_id: this.id,
            org_ids: this.orgIds || '',
            classes_ids: this.trainClass || '',
          });
          if (!data || data.error_code) {
            this.$message.error(data.message);
            return;
          }
          this.$message.success('结业状态已公布');
        },
      });
    },
    // 导入人员名单
    filePlChange(event) {
      this.file = event?.target?.files[0] || null;
      this.savePlFile();
    },
    // 下载异常文档
    getErrorFile() {
      // this.downloadWithBlob(this.errorFile);
      window.open(this.errorFile + '?title=导入异常文档.xlsx');
      this.visible = false;
    },
    // 下载文档
    downloadWithBlob(url) {
      fetch(url).then(res => res.blob().then(blob => {
        var a = document.createElement('a');
        var url = window.URL.createObjectURL(blob);
        var filename = '导入异常文档.xlsx';
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
      }));
    },
    // 导出列表
    download() {
      this.getData('', '', 1);
    },
    // 导入文件返回错误，显示的模板
    uploadFileErrorPrompt(error, msg) {
      error = error || [];
      msg = msg || '上传文件中存在错误';
      this.$notice({
        title: msg,
        content: h => h('div', {}, [
          h('p'),
          ...error.map(err => h('div', {}, [
            (err.serial || err.serial === 0) ? h('h3', `序号 ${err.serial}`) : null,
            ...err.error.map(i => h('p', i)),
          ])),
        ]),
      });
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },

    /**
     * 取消报名
     * @param record
     */
    cancelSignUp(record) {
      this.$confirm({
        content: `确认取消 ${record.truename} 报名？`,
        onOk: () => this.confirmCancelSignUp(record),
      });
    },
    async confirmCancelSignUp(record) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await cancelSignUp({
        id: record.id,
        classes_id: record.classes_id,
      }).finally(() => {
        this.loading = false;
      });

      if (!data || data.error_code) {
        this.$message.error(data?.message || '取消报名失败');
        return;
      }

      this.getData(this.pagination.current, this.pagination.pageSize);
    },

    adjustClass(record) {
      if (this.trainingType !== 1) {
        return;
      }
      if (this.$refs.selectClassModalRef?.show) {
        this.$refs.selectClassModalRef.show({
          trainingId: this.$route.query.id,
          id: record.id,
          classesId: record.classes_id,
          userId: record.user_id,
        });
      }
    },

    /**
     * 打开导入文件弹窗
     */
    showImportFileModal(info) {
      if (this.$refs.importFileModalRef?.show) {
        this.$refs.importFileModalRef.show(info);
      }
    },
    /**
     * 点击导入人员名单
     */
    importPeopleList() {
      this.showImportFileModal({
        title: '导入人员名单',
        templateUrl: this.$store.state.Offline.templates?.offline_import_user_template?.url || '',
        accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
        submit: this.confirmImportPeopleList,
      });
    },
    async confirmImportPeopleList(file) {
      const params = new FormData();
      params.append('file', file);
      params.append('training_id', this.id);

      const data = await saveImportpl(params);

      if (!data || data.error_code) {
        if (data?.error_code === 501) {
          this.uploadFileErrorPrompt(data?.data?.error, data?.data?.message);
          return Promise.reject(data);
        }
        this.$message.error({ content: data?.message || '上传失败' });
        return Promise.reject(data);
      }
      if (data.data.excel_file_url) {
        this.errorFile = data.data.excel_file_url;
        this.visible = true;
      } else {
        this.$message.success({ content: data.message || '上传成功' });
      }
      this.getData();
      return Promise.resolve(data);
    },
    /**
     * 点击导入认证成绩
     */
    importAuthGrade() {
      this.showImportFileModal({
        title: '导入认证成绩',
        templateUrl: this.$store.state.Offline.templates?.offline_import_auth_grade_template?.url || '',
        accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
        submit: this.confirmImportAuthGrade,
      });
    },
    async confirmImportAuthGrade(file) {
      const params = new FormData();
      params.append('file', file);
      params.append('training_id', this.id);

      const data = await saveImportcg(params);

      if (!data || data.error_code) {
        if (data?.error_code === 501) {
          this.uploadFileErrorPrompt(data?.data?.error, data?.data?.message);
          return Promise.reject(data);
        }
        this.$message.error({ content: data?.message || '上传失败' });
        return Promise.reject(data);
      }
      this.$message.success({ content: data.message || '上传成功' });
      this.getData();
      return Promise.resolve(data);
    },
  },
};
</script>

<style scoped lang="scss">
.offlineSign {
  width: 100%;

  .searchForm {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 15px 10px 20px;

    .item {
      padding: 0 5px;
      position: relative;
    }
  }

  .actionDom {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 15px;

    .fileUpload {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
    }
  }

  .table {
    width: 100%;
    padding: 5px 15px 10px 20px;

    .actionBox {
      a {
        padding: 0 5px;
      }
    }
  }
}
</style>

